
import {IdType}     from '_utils/TypeHelper';
import EventManager from '@brainscape/event-manager';
import PropTypes    from 'prop-types';
import React        from 'react';

const RegistrationButton = (props) => {

  const handleClick = (e) => {
    EventManager.emitEvent('reg:register:open', {
      categoryId: props.categoryId,
      packId:     props.packId,
      returnTo:   props.returnTo,
      source:     props.source,
      subject:    props.subject,
    });

    if (props.afterClick) { props.afterClick(); }
  }

  const cn = props.className || 'registration-button';

  return (
    <div className={cn} onClick={handleClick}>
      {props.text || 'Get Started'}
    </div>
  );
}

RegistrationButton.propTypes = {
  afterClick: PropTypes.func,
  categoryId: IdType,
  className:  PropTypes.string,
  packId:     IdType,
  returnTo:   PropTypes.string,
  source:     PropTypes.string,
  subject:    PropTypes.string,
  text:       PropTypes.string,
};

export default RegistrationButton;
