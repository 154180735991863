
import EventManager from '@brainscape/event-manager';
import PropTypes    from 'prop-types';
import React        from 'react';

const LoginButton = (props) => {
  
  const handleClick = (e) => {
    EventManager.emitEvent('reg:login:open', {
      source:  props.source,
      subject: props.subject,
    });
  };

  return (
    <div className='login-button' onClick={handleClick}>Log In</div>
  );
}

LoginButton.propTypes = {
  source:  PropTypes.string,
  subject: PropTypes.string,
};

export default LoginButton;
