
import LoginButton              from '_registration/LoginButton';
import React                    from 'react';
import ReactHelper              from '_utils/ReactHelper';
import RegistrationController   from '_controllers/RegistrationController';
import RegistrationButton       from '_registration/RegistrationButton';
import SearchBar                from '_views/shared/SearchBar'
import UiHelper                 from '_utils/UiHelper';

let navigation,  
    mobileNavbarControls,
    genomeDropdown,
    navbarOptionsWithDropdown,
    navbarOptionsWithLingerEvents,
    talkToUsCta;

const navbar = {

  init() {

    /*
    ==================================================
     CACHE DOM ELEMENTS
    ==================================================
    */

    navigation = document.querySelector('.navbar');

    if (navigation) {
      mobileNavbarControls = navigation.querySelectorAll('.mobile-control');
      genomeDropdown = document.querySelector('.genome-dropdown');
      navbarOptionsWithDropdown = navigation.querySelectorAll('.navbar-link.dropdown');
      navbarOptionsWithLingerEvents = navigation.querySelectorAll('.navbar-list.links .lingerable');
      talkToUsCta = navigation.querySelector('.navbar-list.buttons .talk-to-us');
    }

    /*
    ==================================================
     RENDER REACT COMPONENTS
    ==================================================
    */

    ReactHelper.renderComponent(<RegistrationController />, '#registration-controller');
    ReactHelper.renderComponent(<RegistrationButton />, '#make-flashcards-button');
    ReactHelper.renderComponent(<SearchBar />, '#search-bar');
    ReactHelper.renderComponent(<LoginButton />, '#login-button');
    ReactHelper.renderComponent(<RegistrationButton />, '#registration-button');
    ReactHelper.renderComponent(<RegistrationButton />, '#hidden-cat-reg-button');
    ReactHelper.renderComponent(<RegistrationButton />, '#hidden-pack-reg-button');
    ReactHelper.renderComponent(<RegistrationButton />, '#cat-registration-button');
    ReactHelper.renderComponent(<RegistrationButton />, '#pack-registration-button');

    if (mobileNavbarControls) {
      mobileNavbarControls.forEach(mobileNavControl => {
        mobileNavControl.addEventListener('click', (e) => {
          this.handleMobileNavbarControlsClick(e, mobileNavControl);
        });
      });
    }

    if (navbarOptionsWithDropdown) {
      navbarOptionsWithDropdown.forEach(navbarOptionWithDropdown => {
        navbarOptionWithDropdown.addEventListener('click', (e) => {
          this.handleExpandDropdownClick(e, navbarOptionWithDropdown);
        });
      });
    }

    if (navbarOptionsWithLingerEvents) {
      navbarOptionsWithLingerEvents.forEach(navbarOptionWithLingerEvent => {
        UiHelper.onLinger(navbarOptionWithLingerEvent, (e) => {

          if (navigation.classList.contains('is-open')) {
            return;
          }

          this.handleNavbarLinkOnLinger(e, navbarOptionWithLingerEvent);
        });
      });
    }

    if (talkToUsCta) {
      this.handleAddTalkToUsCta();
    }
  },

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseGenomeDropdownButtonClick(e, navbarLink) {
    e.preventDefault();
    e.stopPropagation();

    this.closeGenomeDropdown(navbarLink);

    navigation.classList.remove('is-open', 'genome');
    this.setBodyPosition();
  },

  handleExpandDropdownOnLinger(e, elem) {
    e.stopPropagation();

    this.closeOtherDropdownMenus(elem);
    this.isDropdownMenuOpen(elem) ? this.closeDropdownMenu(elem) : this.openDropdownMenu(elem);
  },

  handleExpandDropdownClick(e, elem) {
    e.stopPropagation();

    this.closeOtherDropdownMenus(elem);
    this.isDropdownMenuOpen(elem) ? this.closeDropdownMenu(elem) : this.openDropdownMenu(elem);
  },

  handleGenomeBlockClick(e) {
    // only handle if genome link was clicked
    if (e.target.classList.contains('subject-js-link')) {

      e.preventDefault();
      e.stopPropagation();

      const newLocation = e.target.getAttribute('data-link');
      location = (newLocation) ? newLocation : '/subjects';
    }
  },

  handleMobileNavbarControlsClick(e, elem) {
    e.preventDefault();
    e.stopPropagation();

    const isMobileGenomeOpen = navigation.classList.contains('genome');
    const isMobileMenuOpen = navigation.classList.contains('menu');

    if (elem.classList.contains('search-icon')) {
      if (isMobileMenuOpen) {
        this.closeMobileMenu();
        navigation.querySelector('.hamburger-icon').classList.remove('is-active')
      }

      if (isMobileGenomeOpen) {
        this.closeMobileGenome();
        genomeDropdown.querySelector('.close-dropdown').removeEventListener("click", (e) => this.handleCloseGenomeDropdownButtonClick(e, elem));
        elem.classList.remove('is-active')
      } else {
        this.openMobileGenome();
        genomeDropdown.querySelector('.close-dropdown').addEventListener("click", (e) => this.handleCloseGenomeDropdownButtonClick(e, elem));
        elem.classList.add('is-active')
      }
    } else if (elem.classList.contains('hamburger-icon')) {

      if (isMobileGenomeOpen) {
        this.closeMobileGenome();
        navigation.querySelector('.search-icon').classList.remove('is-active')
      } 

      if (isMobileMenuOpen) {
        this.closeMobileMenu();
        elem.classList.remove('is-active')
      } else {
        this.openMobileMenu();
        elem.classList.add('is-active')
      }
    }
  },

  handleNavbarLinkOnLinger(e, navbarLink) {
    e.preventDefault();
    e.stopPropagation();

    if (navbarLink.classList.contains('find-flashcards')) {
      this.openGenomeDropdown(navbarLink);
    }

    this.handleExpandDropdownOnLinger(e, navbarLink);
    this.wireLingerDropdownHandlers(navbarLink);
  },

  handleNavbarMouseleave(e, navbarLink) {
    e.preventDefault();
    e.stopPropagation();

    if (navbarLink.classList.contains('find-flashcards')) {
      this.closeGenomeDropdown(navbarLink);
    }

    this.closeDropdownMenu(navbarLink)
  },

  handleLingerLinkClick(e, navbarLink) {
    e.preventDefault();

    if (e.target.classList.contains('option-link')) {

      e.preventDefault();
      e.stopPropagation();

      const newLocation = e.target.getAttribute('href');
      e.target.parentNode.classList.contains('partnerships') ? window.open(newLocation, '_blank') : location = newLocation;

    } else {
      const linkMappings = {
        'find-flashcards': '/subjects',
        'businesses': '/companies',
        'educators': '/teachers',
      };
      
      const classList = navbarLink.classList;
      location = linkMappings[Array.from(classList).find(cls => linkMappings.hasOwnProperty(cls))];  
    }  
  },  

  handleLingerLinkMouseleave(e, navbarLink) {
    e.preventDefault();
    e.stopPropagation();

    if (navbarLink.classList.contains('find-flashcards')) {
      // if mouse is in genome dropdown, ignore event
      if (genomeDropdown.matches(':hover')) {
        return false;
      }

      this.closeGenomeDropdown(navbarLink);
    }

    this.closeDropdownMenu(navbarLink)
  },

  handleOutsideClick(e, elem) {
    if (elem && elem.contains(e.target)) {
      return false;
    }

    this.closeDropdownMenu(elem);
  },

  handleAddTalkToUsCta() {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        const shouldHide = entry.isIntersecting;
        talkToUsCta.classList.toggle('is-hidden', shouldHide);
      });
    };    
    
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.0 // Trigger when the target is fully hidden
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const target = document.querySelector('.cms-content .band-1');
    observer.observe(target);
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  closeMobileMenu() {
    navigation.classList.remove('is-open', 'menu');
    this.setBodyPosition();
    navigation.querySelector('.options-container').classList.remove('is-showing');
  },

  openMobileMenu() {
    navigation.classList.add('is-open', 'menu');
    this.setBodyPosition();
    navigation.querySelector('.options-container').classList.add('is-showing');
  },

  closeMobileGenome() {
    navigation.classList.remove('is-open', 'genome');
    this.setBodyPosition();
    genomeDropdown.classList.remove('is-showing');
  },

  openMobileGenome() {
    navigation.classList.add('is-open', 'genome');
    this.setBodyPosition();
    genomeDropdown.classList.add('is-showing');
  },

  clearLingerDropdownHandlers(navbarLink) {
    navbarLink.removeEventListener("mouseleave", (e) => this.handleLingerLinkMouseleave(e, navbarLink));

    navbarLink.removeEventListener("click", (e) => this.handleLingerLinkClick(e, navbarLink));

    navigation.removeEventListener("mouseleave", (e) => this.handleNavbarMouseleave(e, navbarLink));

    if (navbarLink.classList.contains('find-flashcards')){

      genomeDropdown.querySelector('.close-dropdown').removeEventListener("click", (e) => this.handleCloseGenomeDropdownButtonClick(e, navbarLink));

      // wire user click on genome dropdown block to see if subject-js-link was clicked
      genomeDropdown.removeEventListener("click", (e) => this.handleGenomeBlockClick(e));
    }
  },

  closeOtherDropdownMenus(selectedDropdown) {
    let allDropdowns = document.querySelectorAll('.navbar .is-open'); 

    allDropdowns.forEach((dropdown) => {
      if (dropdown !== selectedDropdown) {
        dropdown.classList.remove('is-open');
      }
    });
  },

  closeDropdownMenu(elem) {
    if (elem) {
      elem.classList.remove('is-open');
      this.stopOutsideClickMonitor(elem);
      this.clearLingerDropdownHandlers(elem);
    }
  },

  openGenomeDropdown(navbarLink) {
    genomeDropdown.classList.add('is-showing');
    this.wireLingerDropdownHandlers(navbarLink);
  },

  closeGenomeDropdown(navbarLink) {
    genomeDropdown.classList.remove('is-showing');
    this.clearLingerDropdownHandlers(navbarLink);
  },

  isDropdownMenuOpen(elem) {
    return (elem && elem.classList.contains('is-open'));
  },

  openDropdownMenu(elem) {
    if (elem) {
      elem.classList.add('is-open');
      this.startOutsideClickMonitor(elem);
    }
  },

  setBodyPosition() {
    document.body.classList.toggle('mobile-navbar-is-open');
  },

  startOutsideClickMonitor(elem) {
    document.addEventListener('click', (e) => this.handleOutsideClick(e, elem));
  },

  stopOutsideClickMonitor(elem) {
    document.removeEventListener('click', (e) => this.handleOutsideClick(e, elem));
  },

  wireLingerDropdownHandlers(navbarLink) {
    navbarLink.addEventListener("mouseleave", (e) => this.handleLingerLinkMouseleave(e, navbarLink));

    navbarLink.addEventListener("click", (e) => this.handleLingerLinkClick(e, navbarLink));

    navigation.addEventListener("mouseleave", (e) => this.handleNavbarMouseleave(e, navbarLink));

    if (navbarLink.classList.contains('find-flashcards')){

      genomeDropdown.querySelector('.close-dropdown').addEventListener("click", (e) => this.handleCloseGenomeDropdownButtonClick(e, navbarLink));

      // wire user click on genome dropdown block to see if subject-js-link was clicked
      genomeDropdown.addEventListener("click", (e) => this.handleGenomeBlockClick(e));
    }
  },
}

export default navbar;
