
import carouselGrid            from '../../shared/carousel-grid';
import featurePanelsSlider     from '../../shared/featurePanelsSlider';
import marketSearchBar         from '../../shared/market-search-bar';
import videoModal              from '../../shared/video-modal';
import UiHelper                from '_utils/UiHelper';


let publicLandingPage, band1WatchVideoButton, homepageSlider, testimonialsSlider, featurePanels, lazyBackgroundImages, ACTIVE_RANGE_UP_TO;

const landingPage = {

  init: function () {

    publicLandingPage         = document.querySelector('.landing-page');
    band1WatchVideoButton     = publicLandingPage.querySelectorAll('.band-1 .watch-video-button');
    homepageSlider            = publicLandingPage.querySelector('.band-1 .slider');
    testimonialsSlider        = publicLandingPage.querySelector('.band-4 .carousel-grid');
    featurePanels             = publicLandingPage.querySelector('.band-9 .feature-panels');
    lazyBackgroundImages      = publicLandingPage.querySelectorAll('.lazy-background');
    ACTIVE_RANGE_UP_TO = 769;

    /*
    ==================================================
      WIRE BEHAVIORS
    ==================================================
    */

    if (band1WatchVideoButton) {
      let videoUrl;

      band1WatchVideoButton.forEach(item => {
        videoUrl = item.getAttribute('data-video-url') || null;

        item.addEventListener("click", () => {
          this.handleOpenVideoModalRequest(videoUrl);
        });
      })
    }

    if (homepageSlider && !UiHelper.detectIfMobileSize()) {
      this.handleLandingSlider();
    }

    /*
    ==================================================
      INIT CAROUSELS
    ==================================================
    */

    if (testimonialsSlider) {
      carouselGrid.init({
        breakpoint: ACTIVE_RANGE_UP_TO,
        carouselContainer: testimonialsSlider,
      });
    }

    if (featurePanels) {
      featurePanelsSlider.init({
        container: featurePanels,
      });
    }

    if (lazyBackgroundImages) {
      this.lazyLoadBackgroundImages();
    }

    /*
    ==================================================
      INIT SEARCHBAR
    ==================================================
    */

    marketSearchBar.init({
      onFocus: this.handleSearchBarFocus,
      selector: '.search-action',
      shouldSuppressInitialFocus: true,
    });
  },


  /*
  ==================================================
    EVENT HANDLERS
  ==================================================
  */

  handleLandingSlider() {
    const slides = document.querySelectorAll('.slide');
    const indicators = document.querySelectorAll('.indicator');
    let currentSlide = 0;

    function showSlide(index) {
      slides.forEach((slide, i) => {
        const slideWidth = slide.clientWidth;
        slide.style.transform = `translateX(-${index * slideWidth}px)`;
        updateIndicators(index);
      });
    }

    function updateIndicators(index) {
      indicators.forEach(indicator => {
        indicator.classList.remove('active');
      });

      indicators[index].classList.add('active');
    }

    function autoSlide() {
      currentSlide = (currentSlide + 1) % slides.length;
      showSlide(currentSlide);
    }
    
    showSlide(currentSlide);
    setInterval(autoSlide, 5000);
  },

  handleCloseVideoModalRequest() {
    this.isVideoModalOpen(false);
  },

  handleOpenVideoModalRequest(videoUrl) {
    this.isVideoModalOpen(true, videoUrl);
  },

  handleSearchBarFocus() {
    let navbarHeight = document.querySelector('.navbar').offsetHeight;
    let search = document.querySelector('.band-5 .market-search-bar');

    scrollTo(search);

    function scrollTo(element){
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: (element.offsetTop - navbarHeight) - 1 // minus 1 is the amount of pixels of the border-top declaration on the search bar. Without this, the border will not show.
      });
    }
  },


  /*
  ==================================================
    LOCAL UTILS
  ==================================================
  */

  isVideoModalOpen(isOpen=false, videoUrl=null) {
    videoModal.render({
      isOpen: isOpen,
      onCloseRequest: () => this.handleCloseVideoModalRequest(),
      selector: '.modals',
      shouldAutoPlay: true,
      videoUrl: videoUrl,
    });
  },

  lazyLoadBackgroundImages() {
    let lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });

      lazyBackgrounds.forEach(function (lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground);
      });
    }
  }
};

export default landingPage;
