
import EventBus from './EventBus';
import GaHelper from '_utils/GaHelper';

const Tracker = {

  trackDeckConversion(eventData) {
    EventBus.publish('web:deck_conversion', eventData, true);
    console.log('Deck Conversion Event published to EventBus. eventData:', eventData);
  },

  trackDashboardProgress(name) {
    this.trackModalPageview('dashboard_progress', name);
    EventBus.publish('web:dashboard:progress', {name: name}, true);
  },

  trackExperimentEvent(eventData) {
    const eventName = 'experiment_capture:created';
    EventBus.publish(eventName, eventData, true);
    console.log('Experiment Event published to EventBus. eventData:', eventData);
  },

  trackModalPageview(key, value) {
    var path = location.pathname;
    path += location.search ? (location.search + '&') : '?';
    path += key + '=' + value;

    const pageLocation = window.location.origin + path;

    try {
      this.waitThenReportGaPageView(pageLocation);
    } catch {
      try {
        EventBus.publish('error:msg', {msg: 'Unable to trackModalPageview in GA. GA not loaded'}, true);
        console.log('Unable to trackModalPageview in GA. GA not loaded.');
      } catch (e) {
        console.log('Unable to trackModalPageview. GA not loaded and EventBus not available.');
      }
    }
  },

  trackPaywallWarning(name) {
    this.trackModalPageview('paywall_warning', name);
    EventBus.publish('web:paywall:warning', {name: name}, true);
  },

  trackStudyProgress(name) {
    this.trackModalPageview('study_progress', name);
    EventBus.publish('web:study:progress', {name: name}, true);
  },

  waitThenReportGaPageView(pageLocation, tries=0) {
    if (tries > 8) throw new Error('GA not loaded in time');

    if (window.gtag) {
      gtag('event', 'page_view', { 
        page_location: pageLocation, 
        send_to: GaHelper.ga_4_id()
      });

      return true;
    }

    setTimeout(() => this.waitThenReportGaPageView(pageLocation, tries + 1), this.delayFor(tries + 1));
  },

  delayFor(i) {
    return [250, 500, 750, 1000, 2000, 4000, 8000, 15000, 30000][i] || 30000;
  },
}

export default Tracker;
