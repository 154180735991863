
import React                    from 'react';
import ReactDOM                 from 'react-dom';

const ReactHelper = {

  renderComponent(Component, selector) {
    const elems = document.querySelectorAll(selector);

    if (!(elems?.length > 0 && Component)) {
      return false;
    }

    elems.forEach(elem => {
      const data = JSON.parse(elem.getAttribute('data'));
      const newComponent = React.cloneElement(Component, {...data});

      ReactDOM.render(
        newComponent,
        elem.appendChild(document.createElement('div'))
      );
    });
  },
}

export default ReactHelper;
