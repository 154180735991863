
import '@stripe/stripe-js/pure';

import * as Sentry    from '@sentry/browser';
import CookieHelper   from '_utils/CookieHelper';
import DomHelper      from '_utils/DomHelper';
import GaHelper       from '_utils/GaHelper';
import LazyLoad       from "vanilla-lazyload/dist/lazyload";
import SmartBanner    from 'smart-app-banner';

window.CookieHelper   = CookieHelper; 
window.GaHelper       = GaHelper;

// The following is a hack to enable cached Google Analytics snippets to run successfully
window.Util = window.Util || {};
Util = {...Util, ...GaHelper};
Util = {...Util, ...CookieHelper};

const mainShared = {
  init: function() {

    // set up globally available resources
    window.BSC = window.BSC || {};
    BSC.userId = BSC.userId || null;
    BSC.gaDimensions = BSC.gaDimensions || [];
    BSC.gaPageUrl = BSC.gaPageUrl || null;

    // set up polyfills
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }

    // set up lazyloading of images
    let lazyLoad = new LazyLoad({
      elements_selector: ".lazy-load",
      load_delay: 300,
    });

    // set up lazyloading of videos
    let lazyLoadVideo = new LazyLoad({
      elements_selector: ".lazy-load-video",
      load_delay: 300,
      threshold: -100,
    });

    // set up Sentry error tracking
    const sentryEnv = DomHelper.getMetaTagContent('JSSentryEnv');
    const sentryEnabled = DomHelper.getMetaTagContent('JSSentryOn');

    if (sentryEnabled && false) {
      Sentry.init({ 
        dsn         : 'https://5c360771b43f4cb9b6d3e940553f2307@sentry.io/1476831',
        environment : sentryEnv,
      });
    }

    const event = new Event("mainSharedready", {bubbles: true})
    document.dispatchEvent(event)
  }
};

export default mainShared;
