
import EventManager from '@brainscape/event-manager';
import React        from 'react';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestionObjects: [],
      suggestions: [],
      selectedSuggestion: -1,
      q: this.props.q
    }

    this.events      = new EventManager();
    this.searchInput = React.createRef();
  }

  handleChange = (e) => {
    const query = e.target.value;

    this.events.onTimeout('handleChange', 125, () => {
      this.getSuggestionObjects(query);
    });
  }

  handleKeyDown = (e) => {
    switch(e.keyCode) {
      case 13: // Enter Key
        var i = this.state.selectedSuggestion;

        // Short circuit search if there is a selected suggestion
        if (i >= 0) {
          window.location.href = this.state.suggestions[i].route;
          return;
        }

        this.search($(this.searchInput.current).val());
        break;

      case 40: // Down Key
        this.selectDown();
        break;

      case 38: // Up Key
        this.selectUp();
        break;

      default:
        break;
    }
  }

  handleSearch(e) {
    if(this.props.onSubmit !== undefined) {
      e.preventDefault();
      this.props.onSubmit();
    } else {
      this.search(this.state.q);
    }
  }

  componentDidMount() {
    this.getSuggestionObjects(this.props.q);
    this.giveFocus();
    $(this.searchInput.current).val(this.props.q);
  }

  componentWillUnmount() {
    this.events.disable();
  }

  render() {
    var suggestions, autocomplete;

    if(this.state.suggestions.length > 0) {
      suggestions = this.state.suggestions.map((suggestion, i) => {
        var style = "";

        if(i === this.state.selectedSuggestion) {
          style = "selected";
        }

        return (
          <li key={i} className={style}>
            <a href={suggestion.route}>{suggestion.name}</a>
          </li>
        );
      });

      autocomplete = (<ul>{suggestions}</ul>);
    } else {
      suggestions = "";
      autocomplete = "";
    }

    return (
      <div className="market-search-bar">
        <i className="search-icon ion-ios-search-strong" />
        <input
          className='search-field bsc-input bsc-input-lg'
          id={this.props.id}
          name={this.props.name}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder || 'e.g. MCAT, pharma, bar exam, Spanish, Series 7'}
          ref={this.searchInput}
          type='text'
        />
        {this.renderDismissSuggestionsButton()}
        {this.renderDismissSearchBarButton()}
        <button className="search-button bsc-btn bsc-btn-lg" onClick={(e) => this.handleSearch(e)}>
          <span className="search-button-text normal">Search</span>
          <span className="search-button-text small">Go</span>
        </button>
        {this.renderSuggestions(suggestions, autocomplete)}
      </div>
    );
  }

  renderDismissSuggestionsButton() {
    if (this.state.suggestions.length > 0) {
      return (
        <button className="dismiss-suggestions" onClick={() => this.dismissSuggestions()}></button>
      );
    }
  }

  renderDismissSearchBarButton() {
    if (!this.props.isDismissable || this.state.suggestions.length > 0) {
      return null;
    }

    return (
      <button className="dismiss-search-bar" onClick={() => this.handleDismissSearchBar()}>Dismiss Search Bar</button>
    );
  }

  renderSuggestions(suggestions, autocomplete) {
    if (suggestions == "") {
      return null;
    } else {
      return (
        <div className="autocomplete-slide">
          {autocomplete}
        </div>
      );
    }
  }

  suggestions(query, data=this.state.suggestionObjects) {
    query = this.idempotentEncode((query || "").toLowerCase(), true);

    // FIXME: avoid jquery use JS -> https://stackoverflow.com/a/14091157
    return $.grep(data, (s, i) => { 
      // console.log("---> query: " + JSON.stringify(query) + "; s: " + JSON.stringify(s));
      return s.idempotentName.indexOf(query) > -1;
    }).sort((a, b) => {
      return a.idempotentName.indexOf(query) - b.idempotentName.indexOf(query);
    });
  }

  getSuggestionObjects(query) {
    if ((!query) || ("" == query)) {
      this.dismissSuggestions();
      return;
    }

    // stop calling the server for a static list of suggestions over and over!!!
    if (this.state.suggestionObjects.length > 0) {
      this.setState({
        suggestions: this.suggestions(query),
        q: query
      });
      return;
    }

    $.get('/api/search_results/suggestions', {})
      .done((data) => {
        let alteredData = data.map((obj) => {
          return {...obj, idempotentName: this.idempotentEncode(obj.name.toLowerCase(), true) };
        });
        this.setState({
          suggestions: this.suggestions(query, alteredData),
          suggestionObjects: alteredData,
          q: query
        });
      });
  }

  dismissSuggestions() {
    this.setState({
      suggestions: []
    });

    $(this.searchInput.current).val("");
  }

  handleDismissSearchBar() {
    const body = document.querySelector('body');
    body.classList.remove('show-search-bar');
  }


  idempotentDecode(str, isReplaceSpecialChars=false) {
    var result = str;
    try {
      result = decodeURI(str);
      if (isReplaceSpecialChars) {
        result = result.replace(/\%2E/g, '.')
        .replace(/\%25/g, '%');
      }
    } catch(err) {
      //no-op:
    }
    return result;
  }

  idempotentEncode(str, isReplaceSpecialChars=false) {
    const dec = this.idempotentDecode(str, isReplaceSpecialChars);
    var result = encodeURI(dec);
    if (isReplaceSpecialChars) {
      result = result.replace(/\./g, '%2E')
        .replace(/\%\%/g, '%25%')
        .replace(/\%\s/g, '%25 ')
        .replace(/\%\+/g, '%25+');
    }
    // console.log("encoded " + JSON.stringify(str) + " to " + JSON.stringify(result));
    return result;
  }

  search(q) {
    if (!q || q == '') { return; }

    // short circuit if there is a canned page
    var route;
    this.state.suggestions.forEach((val, _) => {
      if (val.name.toLowerCase() == q.toLowerCase()) {
        route = this.idempotentEncode(val.route);
      }
    });

    if (route) {
      window.location.href = route;
      return;
    }

    window.location = `/subjects/${this.idempotentEncode(q, true)}`;
  }

  selectUp() {
    var i = this.state.selectedSuggestion - 1;
    if(i >= -1) {
      this.setState({selectedSuggestion: i});
    }
  }

  selectDown() {
    var i = this.state.selectedSuggestion + 1;
    if(i < this.state.suggestions.length) {
      this.setState({selectedSuggestion: i});
    }
  }

  giveFocus = () => {
    if (this.props.shouldSuppressInitialFocus) {
      return false;
    }

    this.searchInput.current.focus();
  }

  handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }
};

export default SearchBar;
